import { Carousal } from "../Common/Carousal"     
import "../../scss/publicProfile.scss"
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from 'react-router-dom'
import { toast } from "react-toastify"
import { registerBroker, resetRegisterBroker } from "../../Features/Slices/brokSlice"
import{ValidateFields } from "../../Validation/Common/fieldValidation"
var   AddEditBrokerValidation = require('../../Validation/Config/AddEditBroker.json')

export function RegisterBroker() {

//   const [searchParams] = useSearchParams();
//   const navigate = useNavigate();
//   const dispatch = useDispatch()
//  // const brokerIdParam = searchParams.get('id')
//   // dispatch(reset())
//   const [formData, setFormData] = useState({
//     name: '',
//     phoneNumber: '',
//     description: '',
//     address1: '',
//     address2: '',
//     district: '',
//     state: '',
//     country: '',
//     pincode: '',

//   })

//   const { name, phoneNumber, description, address1, address2, district, state, country, pincode } = formData

//   const onchange = (e) => {
//     setFormData((prevState) => ({
//       ...prevState,
//       [e.target.name]: e.target.value
//     }))
//   }

//   const { broker, isRegisterBrokerError, isRegisterBrokerSuccess,
//     isRegisterBrokerLoading, RegisterBrokermessage } =
//     useSelector(
//       (state) => state.brok
//     )

//   useEffect(() => {
//     if (isRegisterBrokerError) {
//       toast.error(RegisterBrokermessage)
//     }
//     if (isRegisterBrokerSuccess) {
//       dispatch(resetRegisterBroker())
//       navigate('/AddBrokerImage?id='+ broker._id, { replace: true });
//     }


//   }, [broker, isRegisterBrokerError, isRegisterBrokerSuccess, isRegisterBrokerLoading, RegisterBrokermessage, navigate], dispatch)

  

//   const onSubmit = (e) => {
//     e.preventDefault();

//     setFormData((prevState) => ({
//       ...prevState,
//       [e.target.name]: e.target.name
//     }))

//     const brokerData = {
//       name, phoneNumber, description, address1, address2, district, state,
//       country, pincode
//     }

//     let message = "";
//     let hasRequiredfieldValidation = false
    
//     const brokerReqFields = {
//       name, phoneNumber, description, address1, district, state,
//       country, pincode
//     }

//     for (const [key, value] of Object.entries(brokerReqFields)) {

//         if(value == ""){
//           hasRequiredfieldValidation = true;
//          return toast.error('Please fill (*) required field')
//         }
//     }

// if(hasRequiredfieldValidation == false)
// {
//     for (const [key, value] of Object.entries(brokerData)) {
//       let arrValidation = AddEditBrokerValidation.filter(validatebroker => validatebroker.fieldName === key)
//       for (const currentObject of arrValidation) {
//           let message = ValidateFields(currentObject, value);
//           if (message != '') {
//             toast.error(message)
//           }
//       }
//     }
//   }

//     if(hasRequiredfieldValidation == false && message == '')
//     {
//       dispatch(registerBroker(brokerData))

//       if (isRegisterBrokerSuccess == true) {
//         toast.success(RegisterBrokermessage)
//       }
//       else if (isRegisterBrokerError == true) {
//         toast.success(RegisterBrokermessage)
//       }
//     }
//   }

//     const onCancelClick = () => {
//       navigate('/BrokerList')
//     }

//     const onResetClick = (e) => {
      
//       //dispatch(resetRegisterBroker())
//     }
 
// const oniconClick=()=>{
//   <input type="file" />
// }

const oniconClick = () => {
  const input = document.createElement("input");
  input.type = "file";
  input.click();
};



    return (
      <>
        <div className="container">
        <div className="navbar ">
                 <div> <h2>Add Broker</h2>
              </div>
               <button className="float-end  btn btn-success" type="submit " style={{width:75}}>Add</button>
            </div>
            <div className="d-flex mt-3"><h6>1.Basic Details</h6></div>
            <div className="row mt-3">
              <div className="form-group col-4">
                <label htmlFor="name">Name</label><br />
                <input className="form-control border border-success" type="text" id="name" name="name" placeholder="Enter name"/>
              </div>
              <div className="form-group col-4">
                <label htmlFor="phoneNumber">Phone Number</label><br />
                <input className="form-control border border-success" type="text" id="phoneNumber" name="phoneNumber" placeholder="Enter Phone Number"/>
              </div>
              <div className="form-group col-4">
                <label htmlFor="email">Email</label><br />
                <input className="form-control border border-success" type="email" id="email" name="email" placeholder="Enter Email"/>
              </div>
            </div>

            <div className="row mt-3">
              <div className="form-group col-4">
                <label htmlFor="address1">Address1</label><br />
                <input className="form-control border border-success" type="text" id="address1" name="address1" placeholder="Enter Address 1"/>
              </div>
              <div className="form-group col-4">
                <label htmlFor="address2">Address2</label><br />
                <input className="form-control border border-success" type="text" id="address2" name="address2" placeholder="Enter Address 2"/>
              </div>
              <div className="form-group col-4">
                <label htmlFor="district">District</label><br />
                <select className="form-control border border-success" name="district" id="district">
                  <option value="">Select</option>
                  <option value="Kanya Kumari">Kanya Kumari</option>
                  <option value="Thirunelveli">Thirunelveli</option>
                </select>
              </div>
            </div>

            <div className="row mt-3">
              <div className="form-group col-4">
                <label htmlFor="state">State</label><br />
                <select className="form-control border border-success" name="state" id="state">
                  <option value="">Select</option>
                  <option value="Tamil Nadu">Tamil Nadu</option>
                </select>
              </div>

              <div className="form-group col-4">
                <label htmlFor="pincode">Pincode</label><br />
                <input className="form-control border border-success" type="text" id="pincode" name="pincode" placeholder="Enter Pincode "/>
              </div>
            </div>

            <div className="row mt-3">
              <div className="form-group col-4">
                <label htmlFor="brokerCategory">Broker Category</label><br />
                <select className="form-control border border-success" name="brokerCategory" id="brokerCategory">
                  <option value="">Select</option>
                 <option value="Gold">Gold</option>
                 <option value="Silver">Silver</option>
                 <option value="Bronze">Bronze</option>
                </select>
              </div>

              <div className="form-group col-4">
                <label htmlFor="rank">Rank</label><br />
                <input className="form-control border border-success" type="text" id="rank" name="rank" placeholder="Enter rank "/>
              </div>
            </div>

            <div className="row mt-3">
            <div className="form-group col-4">
                <label htmlFor="description">Matrimony name</label><br />
                <input className="form-control border border-success" type="text" id="description" name="description" placeholder="Enter Matrimony name"/>
              </div>
            </div>


            
            <br />

            <div className="d-flex mt-3"><h6>2.Upload</h6></div>
          
          <div className="row">
            <label htmlFor="">Upload Image</label><br />
            <div className="btn btn-success" style={{width:140}} onClick={oniconClick}> <svg style={{marginRight:10}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-upload" viewBox="0 0 16 16">
  <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>
  <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708z"/>
</svg>upload</div>
          </div>

          <br />
          <div className="d-flex mt-3"><h6>3.Login Details</h6></div>
          <div className="row mt-3">
              <div className="form-group col-4">
                <label htmlFor="name">Login User Name</label><br />
                <input className="form-control border border-success" type="text" id="password" name="name" placeholder="Enter Login User Name"/>
              </div>
              <div className="form-group col-4">
                <label htmlFor="password">Login Password</label><br />
                <input className="form-control border border-success" type="text" id="password" name="password" placeholder="Enter Password"/>
              </div>
              <div className="form-group col-4">
                <label htmlFor="password">Confirm Password</label><br />
                <input className="form-control border border-success" type="email" id="password" name="password" placeholder="Enter Password"/>
              </div>
            </div>
<br />
<button className="btn btn-secondary" type="">Cancel</button>
        </div>
      </>
    )
  }