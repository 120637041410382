import "../../scss/publicProfile.scss"
import { getProfileDetailsById } from "../../Features/Slices/profSlice"
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from "react"
import { useSearchParams } from 'react-router-dom'
import { Link } from "react-router-dom";
import "../../scss/profileList.scss"

export function Profile(){
    const dispatch=useDispatch()
    const [searchParams] = useSearchParams();
    const profileId = searchParams.get('id')


      const profile =
          useSelector(
              (state) => state.prof
          )
      useEffect(() => {
          
            dispatch(getProfileDetailsById(profileId))
           console.log(profile.profileDetails)
      }, [])


      const hasHoroScopeDataR = (horoScope) => {
        return (
          horoScope?.meenaR?.trim() ||
          horoScope?.meshaR?.trim() ||
          horoScope?.vrishbaR?.trim() ||
          horoScope?.mithunaR?.trim() ||
          horoScope?.khumbhaR?.trim() ||
          horoScope?.karkataR?.trim() ||
          horoScope?.makaraR?.trim() ||
          horoScope?.simhaR?.trim() ||
          horoScope?.dhanuR?.trim() ||
          horoScope?.vrishikaR?.trim() ||
          horoScope?.tulaR?.trim() ||
          horoScope?.kanyaR?.trim()
        );
      };
    
    
      const hasHoroScopeDataA = (horoScope) => {
        return (
          
          horoScope?.meshaA?.trim() ||
          horoScope?.vrishbaA?.trim() ||
          horoScope?.mithunaA?.trim() ||
          horoScope?.khumbhaA?.trim() ||
          horoScope?.karkataA?.trim() ||
          horoScope?.makaraA?.trim() ||
          horoScope?.simhaA?.trim() ||
          horoScope?.dhanuA?.trim() ||
          horoScope?.vrishikaA?.trim() ||
          horoScope?.tulaA?.trim() ||
          horoScope?.kanyaA?.trim()
        );
      };
    const backuButtonUrl='/ProfileList?'
    const backEditHoroscope='/AddProfileImage?id='+ profileId
    return(<>
    <div>
        <Link to={backuButtonUrl} className="dropdown-item d-flex align-items-center">
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#1aa179" className="bi bi-arrow-left-circle" viewBox="0 0 16 16">
            <path fillRule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z"/>
          </svg>
          <p className="h6 mb-0 ms-2">Go Back</p>
        </Link>
    </div>
    <br /><br />
   <div>
    <Link to={backEditHoroscope} className="dropdown-item">
      <p className="h4"> Horoscope
       <span>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
        <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"/>
      </svg>
       </span></p>
    
    </Link>

    {profile.isProfileDetailsByIdError && profile.isError ? <div>Error while loading</div> : null}

<div className="card-container"> 
  {profile.isProfileDetailsByIdLoading && ( 
    <>            
        <div className="section-skeleton">
          <div className="skeleton skeleton-Detail-section2"></div>
        </div>
    </>

) } </div>

{profile.isProfileDetailsByIdSuccess && !profile.isProfileDetailsByIdLoading && (
  <>
    <div id="dvhoroscope" className="row">
      <div className="col-md-8">
        {profile?.profileDetails?.horoScope && hasHoroScopeDataR(profile.profileDetails.horoScope) ? (
          <div className="row">
            <div className="row">
              <div className="col-3 horo-column">
                {profile?.profileDetails?.horoScope?.meenaR?.split(' ').map((item, index) => (
                  <span className={item === 'ல' ? 'red-text' : ''} key={index}>
                    {item === ' ' ? '\u00A0' : item} {/* Handle spaces */}
                  </span>
                ))}
              </div>

              <div className="col-3 horo-column">
                {profile?.profileDetails?.horoScope?.meshaR?.split(' ').map((item, index) => (
                  <span className={item === 'ல' ? 'red-text' : ''} key={index}>
                    {item === ' ' ? '\u00A0' : item} {/* Handle spaces */}
                  </span>
                ))}
              </div>

              <div className="col-3 horo-column">
                {profile?.profileDetails?.horoScope?.vrishbaR?.split(' ').map((item, index) => (
                  <span className={item === 'ல' ? 'red-text' : ''} key={index}>
                    {item === ' ' ? '\u00A0' : item} {/* Handle spaces */}
                  </span>
                ))}
              </div>

              <div className="col-3 horo-column">
                {profile?.profileDetails?.horoScope?.mithunaR?.split(' ').map((item, index) => (
                  <span className={item === 'ல' ? 'red-text' : ''} key={index}>
                    {item === ' ' ? '\u00A0' : item} {/* Handle spaces */}
                  </span>
                ))}
              </div>
            </div>

            <div className="row">
              <div className="col-3 horo-column">
                {profile?.profileDetails?.horoScope?.khumbhaR?.split(' ').map((item, index) => (
                  <span className={item === 'ல' ? 'red-text' : ''} key={index}>
                    {item === ' ' ? '\u00A0' : item} {/* Handle spaces */}
                  </span>
                ))}
              </div>

              <div className="col-6 text-center" style={{ fontWeight: 'bold' }}>ராசி</div>
              <div className="col-3 horo-column">
                {profile?.profileDetails?.horoScope?.karkataR?.split(' ').map((item, index) => (
                  <span className={item === 'ல' ? 'red-text' : ''} key={index}>
                    {item === ' ' ? '\u00A0' : item} {/* Handle spaces */}
                  </span>
                ))}
              </div>
            </div>


            <div className="row">
              <div className="col-3 horo-column">
                {profile?.profileDetails?.horoScope?.makaraR?.split(' ').map((item, index) => (
                  <span className={item === 'ல' ? 'red-text' : ''} key={index}>
                    {item === ' ' ? '\u00A0' : item} {/* Handle spaces */}
                  </span>
                ))}
              </div>


              <div className="col-6"></div>
                <div className="col-3 horo-column">
                  {profile?.profileDetails?.horoScope?.simhaR?.split(' ').map((item, index) => (
                    <span className={item === 'ல' ? 'red-text' : ''} key={index}>
                      {item === ' ' ? '\u00A0' : item} {/* Handle spaces */}
                    </span>
                  ))}
                </div>
              </div>


              <div className="row">
                <div className="col-3 horo-column">
                  {profile?.profileDetails?.horoScope?.dhanuR?.split(' ').map((item, index) => (
                    <span className={item === 'ல' ? 'red-text' : ''} key={index}>
                      {item === ' ' ? '\u00A0' : item} {/* Handle spaces */}
                    </span>
                  ))}
                </div>


              <div className="col-3 horo-column">
                {profile?.profileDetails?.horoScope?.vrishikaR?.split(' ').map((item, index) => (
                  <span className={item === 'ல' ? 'red-text' : ''} key={index}>
                    {item === ' ' ? '\u00A0' : item} {/* Handle spaces */}
                  </span>
                ))}
              </div>


              <div className="col-3 horo-column">
                {profile?.profileDetails?.horoScope?.tulaR?.split(' ').map((item, index) => (
                  <span className={item === 'ல' ? 'red-text' : ''} key={index}>
                    {item === ' ' ? '\u00A0' : item} {/* Handle spaces */}
                  </span>
                ))}
              </div>
              

              <div className="col-3 horo-column">
                {profile?.profileDetails?.horoScope?.kanyaR?.split(' ').map((item, index) => (
                  <span className={item === 'ல' ? 'red-text' : ''} key={index}>
                    {item === ' ' ? '\u00A0' : item} {/* Handle spaces */}
                  </span>
                ))}
              </div>
            </div>
          </div>

        ) : (
          // Display this message if no horoscope data is found or all values are empty
          <div className="row">
            <Link to={backEditHoroscope} className="dropdown-item">
              <p>
                <span style={{ fontWeight: 'bold', color: '#ff5722' }}>ராசி</span> not available. 
                <span 
                    style={{ cursor: 'pointer', color: '#1aa179', display: 'inline-flex', alignItems: 'center', marginLeft: '8px'}} 
                >
                  Add Horoscope
                  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-plus-square" viewBox="0 0 16 16" style={{ marginLeft: '8px' }}>
                    <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z"/>
                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"/>
                  </svg>
                </span>
              </p>
            </Link>     
          </div>
        )}

        <br></br><br/><br/>

        {profile?.profileDetails?.horoScope && hasHoroScopeDataA(profile.profileDetails.horoScope) ? (

          <div className="row">
            <div className="row">
              <div className="col-3 horo-column">
                {profile?.profileDetails?.horoScope?.meenaA?.split(' ').map((item, index) => (
                  <span style={{ color: item === 'ல' ? 'red' : 'black' }} key={index}>
                    {item === ' ' ? '\u00A0' : item} {/* Handle spaces as well */}
                  </span>
                ))}
              </div>


              <div className="col-3 horo-column">
                {profile?.profileDetails?.horoScope?.meshaA?.split(' ').map((item, index) => (
                  <span style={{ color: item === 'ல' ? 'red' : 'black' }} key={index}>
                    {item === ' ' ? '\u00A0' : item} {/* Handle spaces as well */}
                  </span>
                ))}
              </div>


              <div className="col-3 horo-column">
                {profile?.profileDetails?.horoScope?.vrishbaA?.split(' ').map((item, index) => (
                  <span style={{ color: item === 'ல' ? 'red' : 'black' }} key={index}>
                    {item === ' ' ? '\u00A0' : item} {/* Handle spaces as well */}
                </span>
                ))}
              </div>


              <div className="col-3 horo-column">
                {profile?.profileDetails?.horoScope?.mithunaA?.split(' ').map((item, index) => (
                  <span style={{ color: item === 'ல' ? 'red' : 'black' }} key={index}>
                    {item === ' ' ? '\u00A0' : item} {/* Handle spaces as well */}
                </span>
                ))}
              </div>
            </div>


            <div className="row">
              <div className="col-3 horo-column">
                {profile?.profileDetails?.horoScope?.khumbhaA?.split(' ').map((item, index) => (
                  <span style={{ color: item === 'ல' ? 'red' : 'black' }} key={index}>
                    {item === ' ' ? '\u00A0' : item} {/* Handle spaces as well */}
                  </span>
                ))}
              </div>


              <div className="col-6 text-center" style={{ fontWeight: 'bold' }}>அம்சம்</div>
                    
              <div className="col-3 horo-column">
                {profile?.profileDetails?.horoScope?.karkataA?.split(' ').map((item, index) => (
                  <span style={{ color: item === 'ல' ? 'red' : 'black' }} key={index}>
                    {item === ' ' ? '\u00A0' : item} {/* Handle spaces as well */}
                  </span>
                ))}
              </div>
            </div>


            <div className="row">
              <div className="col-3 horo-column">
                {profile?.profileDetails?.horoScope?.makaraA?.split(' ').map((item, index) => (
                  <span style={{ color: item === 'ல' ? 'red' : 'black' }} key={index}>
                    {item === ' ' ? '\u00A0' : item} {/* Handle spaces as well */}
                  </span>
                ))}
              </div>


              <div className="col-6"></div>
                <div className="col-3 horo-column">
                  {profile?.profileDetails?.horoScope?.simhaA?.split(' ').map((item, index) => (
                    <span style={{ color: item === 'ல' ? 'red' : 'black' }} key={index}>
                      {item === ' ' ? '\u00A0' : item} {/* Handle spaces as well */}
                    </span>
                  ))}
                </div>
              </div>


              <div className="row">
                <div className="col-3 horo-column">
                  {profile?.profileDetails?.horoScope?.dhanuA?.split(' ').map((item, index) => (
                    <span style={{ color: item === 'ல' ? 'red' : 'black' }} key={index}>
                      {item === ' ' ? '\u00A0' : item} {/* Handle spaces as well */}
                    </span>
                  ))}
                </div>


              <div className="col-3 horo-column">
                {profile?.profileDetails?.horoScope?.vrishikaA?.split(' ').map((item, index) => (
                  <span style={{ color: item === 'ல' ? 'red' : 'black' }} key={index}>
                    {item === ' ' ? '\u00A0' : item} {/* Handle spaces as well */}
                  </span>
                ))}
              </div>


              <div className="col-3 horo-column">
                {profile?.profileDetails?.horoScope?.tulaA?.split(' ').map((item, index) => (
                  <span style={{ color: item === 'ல' ? 'red' : 'black' }} key={index}>
                    {item === ' ' ? '\u00A0' : item} {/* Handle spaces as well */}
                  </span>
                ))}
              </div>


              <div className="col-3 horo-column">
                {profile?.profileDetails?.horoScope?.kanyaA?.split(' ').map((item, index) => (
                  <span style={{ color: item === 'ல' ? 'red' : 'black' }} key={index}>
                    {item === ' ' ? '\u00A0' : item} {/* Handle spaces as well */}
                  </span>
                ))}
              </div>
            </div>
          </div>

        ) : (
          // Display this message if no horoscope data is found or all values are empty
          <div className="row">
            <Link to={backEditHoroscope} className="dropdown-item">
              <p>
                <span style={{ fontWeight: 'bold', color: '#ff5722' }}>அம்சம்</span> not available. 
                <span 
                  style={{ cursor: 'pointer', color: '#1aa179', display: 'inline-flex', alignItems: 'center', marginLeft: '8px'}} 
                >
                  Add Horoscope
                  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-plus-square" viewBox="0 0 16 16" style={{ marginLeft: '8px' }}>
                    <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z"/>
                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"/>
                  </svg>
                </span>
              </p>
            </Link>     
          </div>
        )}


      </div>

    </div>

    </> 
  ) }
</div>
  </>
)
}