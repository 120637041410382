import { useEffect, useState, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { registeruser, getRegisterUserOTP, reset, VerifyRegisterOTP } from "../../Features/Slices/authSlice";
import { toast } from "react-toastify";
import "../../scss/register.css";
import signup from "../../img/signup.png";

export function Register() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [captchaToken, setToken] = useState(null);

  // Reference to the reCAPTCHA widget
  const recaptchaRef = useRef();

  // Form state
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    password: '',
    confirmPassword: '',
    otp: '',
    emailotp:''
  });

  const { name, email, phoneNumber, password, confirmPassword, otp, emailotp } = formData;

  // Handle form input changes
  const onchange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }));
  };

  const { user, isError, isSuccess, isLoading, message, isSuccessMessage, isOTPSuccess,isOTPError, messageOTP ,isVerifySuccess,isVerifyMessage,isVerifyError,} = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    dispatch(reset());

    if (!isSuccessMessage && message !== '') {
      toast.error(message);
    }
    if(isSuccess){
      toast.success(isSuccessMessage)
    }
    if(isError){
      toast.error(message)
      return
    }
    if(isOTPError){
      toast.error(messageOTP)
      setIsOtpVisible(false)
    }

    if(isOTPSuccess){
      toast.success(messageOTP)
      setIsOtpVisible(true)
    }

    if (isSuccessMessage && message !== '') {
      toast.success(message);
      navigate('/Login', { replace: true });
    }
   
    if(isVerifyError){
      toast.error(isVerifyMessage)
      setIsOtpVerified(false);
    }
    if(isVerifySuccess){
      toast.success(isVerifyMessage)
       setIsOtpVerified(true);
    }
  }, [user, isError, isSuccess, isLoading, message, isSuccessMessage, isOTPSuccess, messageOTP, isVerifySuccess,isVerifyMessage,isVerifyError,navigate, dispatch]);


// Handle form submission for registration
const onSubmit = async (e) => {
    e.preventDefault();

    // Trigger reCAPTCHA to get the token
    if (recaptchaRef.current) {
        try {
            // Execute reCAPTCHA to get the token
            const recaptchaToken = await recaptchaRef.current.execute();
            console.log("reCAPTCHA token received:", recaptchaToken);
            setToken(recaptchaToken); // Store the token in the state

            // Proceed with submitting the form data
            if (recaptchaToken) {
                // Prepare user data
                
                const data = {
                    name,
                    email,
                    phoneNumber,
                    password,
                    otp,
                    confirmPassword,
                    emailotp,
                    captchaToken: recaptchaToken, // Pass token here
                };

                console.log("Form data:", data); // Log the user data for debugging

                dispatch(registeruser(data));
            } else {
                toast.error("reCAPTCHA verification failed!");
            }
        } catch (error) {
            console.log("Error executing reCAPTCHA:", error);
        }
    }
};

  // OTP section visibility and form state
  const [isVerifying, setIsVerifying] = useState(false);
  const [isOtpVisible, setIsOtpVisible] = useState(false);
  const [isOtpVerified, setIsOtpVerified] = useState(false);

//GETOTP
const getOTP = async (e) => {
    e.preventDefault();

    // Trigger reCAPTCHA to get the token
    if (recaptchaRef.current) {
        try {
            // Execute reCAPTCHA to get the token
            const recaptchaToken = await recaptchaRef.current.execute();
            console.log("reCAPTCHA token received:", recaptchaToken);
            setToken(recaptchaToken); // Store the token in the state

            // Proceed with submitting the form data
            if (recaptchaToken) {
                // Prepare user data
                const data = {
                    name,
                    email,
                    phoneNumber,
                    captchaToken: recaptchaToken, // Pass token here
                };

                console.log("Form data:", data); // Log the user data for debugging


                dispatch(getRegisterUserOTP(data));
                setIsOtpVisible(true); // Show OTP input field
            } else {
                toast.error("reCAPTCHA verification failed!");
            }
        } catch (error) {
            console.log("Error executing reCAPTCHA:", error);
        }
    }
};


//verifyOTP
const verifyOTP = async (e) => {
  e.preventDefault();

  // Trigger reCAPTCHA to get the token
  if (recaptchaRef.current) {
      try {
          // Execute reCAPTCHA to get the token
          const recaptchaToken = await recaptchaRef.current.execute();
          console.log("reCAPTCHA token received:", recaptchaToken);
          setToken(recaptchaToken); // Store the token in the state

          // Proceed with submitting the form data
          if (recaptchaToken) {
              // Prepare user data
              const data = {
                  phoneNumber,
                  email,
                  otp,
                  emailotp,
                  captchaToken: recaptchaToken, // Pass token here
              };

              console.log("Form data:", data); // Log the user data for debugging


              dispatch(VerifyRegisterOTP({data}))
          } else {
              toast.error("reCAPTCHA verification failed!");
          }
      } catch (error) {
          console.log("Error executing reCAPTCHA:", error);
      }
  }
};



  return (
    <>
      <div className="page-content page-container" id="dvuserregister">
        <div className="row">
          <div className="col-md-6">
            <div className="row">
              <div className="app-container">
                <img src={signup} alt="Sign Up" className="responsive-image"  />
              </div>
            </div>
          </div>

          <div className="col-md-6 app-container">
            <form className="mb-5">  
              <div className="card-body col-11">
                {/* Form Fields */}
                {!isOtpVisible && !isOtpVerified && (
                  <>
                    <h2 className="card-title text-success">Sign Up</h2>
                    <p className="text-center fst-italic text-success">Create a New Account with Utthira Matrimony</p>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control mb-3"
                        name="name"
                        id="name"
                        onChange={onchange}
                        placeholder="User Name"
                      />
                    </div>

                    <div>
                      <input
                        type="email"
                        className="form-control mb-3"
                        name="email"
                        id="email"
                        onChange={onchange}
                        placeholder="Email Id"
                      />
                    </div>

                    <div>
                      <input
                        type="text"
                        name="phoneNumber"
                        id="phoneNumber"
                        className="form-control mb-3"
                        onChange={onchange}
                        placeholder="Phone Number"
                      />
                    </div>

                  <ReCAPTCHA
                      sitekey="6LfOt60qAAAAAC7D3mqw1FwDQVGDcsWUwTX8PXXy"
                      size="invisible"
                      ref={recaptchaRef} // Add ref to the component
                  />

                    <div>
                      <button
                        onClick={getOTP}
                        className="btn btn-success">
                        Get OTP
                      </button>
                    </div>
                    
                     <p>Already have an account?<a href="Login" className="ms-4">Login</a></p> 
                    
                  </>
                )}

                {/* OTP Input Section */}
                {isOtpVisible && !isOtpVerified && (
                  <>
                    <h2 className="card-title text-success">Verify OTP</h2>
                    <div>
                      <input
                        type="text"
                        name="otp"
                        id="otp"
                        onChange={onchange}
                        placeholder="Enter OTP"
                        className="form-control mb-3"
                      />
                      <input
                        type="text"
                        name="emailotp"
                        id="emailotp"
                        onChange={onchange}
                        placeholder="Enter Email OTP"
                        className="form-control mb-3"
                      />
                      
                      <ReCAPTCHA
                        sitekey="6LfOt60qAAAAAC7D3mqw1FwDQVGDcsWUwTX8PXXy"
                        size="invisible"
                        ref={recaptchaRef} // Add ref to the component
                      />
                      <p>Don't receive the OTP ? <a   onClick={(e) => getOTP(e)} href="">Resend</a></p> 
                    </div>
                    {/* <br /> */}
                    <div>
                      <button
                        onClick={verifyOTP}
                        className="btn btn-success"
                        // style={{ backgroundColor: "#1aa179", color: "white" }}
                         disabled={isLoading}>
                        {isVerifying ? "Verifying..." : "Verify OTP"}
                      </button>
                    </div>
                  </>
                )}

                {/* Register Button after OTP Verification */}
                {isOtpVerified && (
                  <div>
                    <h2 className="card-title text-success">Password</h2>
                    <div>
                      <input
                        type="password"
                        name="password"
                        id="password"
                        className="form-control mb-3"
                        onChange={onchange}
                        placeholder="Enter Password"
                      />
                      <input
                        type="password"
                        name="confirmPassword"
                        id="confirmPassword"
                        className="form-control mb-3"
                        onChange={onchange}
                        placeholder="Confirm Password"
                      />
                      <ReCAPTCHA
                        sitekey="6LfOt60qAAAAAC7D3mqw1FwDQVGDcsWUwTX8PXXy"
                        size="invisible"
                        ref={recaptchaRef} // Add ref to the component
                      />
                       <input type="checkbox" required /> I accept terms & conditions
                    </div>
                    <button
                      onClick={onSubmit}
                      className="btn btn-success">
                      Register
                    </button>
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
