import authAxious from '../Config/interceptor'

const API_URL_REGISTER_PROFILE ='/profile/'
const API_URL_UPDATE_PROFILE ='/profile/updateProfile'
const API_URL_FETCH_PROFILE ='/profile/getAllProfilesByBrokerId'
const API_URL_FETCH_PROFILE_DETAILS ='/profile/getProfileDetails'
const API_URL_FETCH_BROKER_DETAILS ='/profile/getBrokerDetailsById'
const API_URL_FETCH_PROFILE_URLS ='cloud/getProfileImageUrl'
const API_URL_REMOVE_PROFILE ='cloud/removeProfileImage'
const API_URL_SEARCH_PROFILE = '/profile/searchProfile'
const API_URL_DELETE_PROFILE ='/profile/deleteProfile'
const API_URL_UDATE_HOROSCOPE ='/profile/updateHoroscope'
const API_URL_SET_PROFILE ='/profile/setProfilePhoto'

const API_URL_BROKER_APPROVED = '/profile/brokerApproved'
const API_URL_BROKER_CREATED_PROFILE = '/profile/getBrokerCreatedProfiles'
const API_URL_GET_ADMIN_APPROVED_PROFILES = '/profile/getAdminApprovedProfiles'

const registerProfile = async(data) =>{
    const response = await authAxious.post(API_URL_REGISTER_PROFILE,{data})
    return response.data;
}

const updateProfile = async(data) =>{
    const response = await authAxious.post(API_URL_UPDATE_PROFILE,{data})
    return response.data;
}
const fetchprofileByBroker = async(data) =>{
    //const response = await authAxious.post(API_URL_FETCH_PROFILE,{"brokerId":data})
    const response = await authAxious.post(API_URL_FETCH_PROFILE,data)
    return response.data;
}

const fetchprofileDetailsById = async(data) =>{
    const response = await authAxious.post(API_URL_FETCH_PROFILE_DETAILS,{"profileId":data})
    return response.data;
}

const getbrokerDetailsById = async(data) =>{
    const response = await authAxious.post(API_URL_FETCH_BROKER_DETAILS,{"brokerId":data})
    return response.data;
}
const serchProfiles = async(data) =>{
    const response = await authAxious.post(API_URL_SEARCH_PROFILE,data)
    return response.data;
}

const getProfileImageUrl = async(data) =>{
    const response = await authAxious.post(API_URL_FETCH_PROFILE_URLS,data)
    return response.data.data;
}

const setProfilePicture = async(data) =>{
    const response = await authAxious.post(API_URL_SET_PROFILE,data)
    return response.data;
}

const removeProfileImage = async(data) =>{
    const response = await authAxious.post(API_URL_REMOVE_PROFILE,data)
    return response.data;
}

const deleteProfile = async(data) =>{
    const response = await authAxious.post(API_URL_DELETE_PROFILE,data)
    return response.data;
}

const updateHoroscope = async(data) =>{
    const response = await authAxious.post(API_URL_UDATE_HOROSCOPE,data)
    return response.data;
}

const getBrokerApprovedProfiles = async(data)=>{
    const response = await authAxious.post(API_URL_BROKER_APPROVED,data)
    return response.data;
}

const getBrokerCreatedProfiles = async(data)=>{
    const response = await authAxious.post(API_URL_BROKER_CREATED_PROFILE,data)
    return response.data;
}

const getAdminApprovedProfiles = async(data)=>{
    const response = await authAxious.post(API_URL_GET_ADMIN_APPROVED_PROFILES,data)
    return response.data;
}

const profileService ={
    registerProfile,
    updateProfile,
    fetchprofileByBroker,
    fetchprofileDetailsById,
    getbrokerDetailsById,
    getProfileImageUrl,
    removeProfileImage,
    serchProfiles,
    deleteProfile,
    updateHoroscope,
    setProfilePicture,
    getBrokerApprovedProfiles,
    getBrokerCreatedProfiles,
    getAdminApprovedProfiles
}

export default profileService 
