
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useEffect, useState } from "react";
import { uploadfile,updateHoroscope } from "../../Features/Slices/profSlice"
import { getProfileDetailsById, resetImageUploadByState,resetUpdateHoroscope } from "../../Features/Slices/profSlice"
import { toast } from "react-toastify"
import { Link } from "react-router-dom";
import "../../scss/horoscopeCheckboxGrid.scss"
import "../../scss/profileList.scss"
import "../../scss/common.scss"
export function AddProfileImage() {

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const profileIdParam = searchParams.get('id')


  const profileUploadImage =
    useSelector(
      (state) => state.prof
    )


  const { isUploadProfileSuccess, UploadProfilemessage,isUpdateHoroscopeLoading,isUpdateHoroscopeSuccess,messageUpdateHoroscope,isUpdateHoroscopeProfileError } =
    useSelector(
      (state) => state.prof
    )

  useEffect(() => {
    if (isUploadProfileSuccess) {
      dispatch(resetImageUploadByState())
      if (UploadProfilemessage.isSuccess) {
        toast.success(UploadProfilemessage.message)
      }
      else  {
        toast.error(UploadProfilemessage.message)
      }
    }

  }, [isUploadProfileSuccess, UploadProfilemessage, navigate], dispatch)

  useEffect(() => {
     
      if (isUpdateHoroscopeSuccess) {
        toast.success(messageUpdateHoroscope)
        dispatch(resetUpdateHoroscope())
      }
      else if(isUpdateHoroscopeProfileError) {
        toast.error(UploadProfilemessage.messageUpdateHoroscope)
        dispatch(resetUpdateHoroscope())
      }
  }, [isUpdateHoroscopeSuccess, UploadProfilemessage,isUpdateHoroscopeProfileError, navigate], dispatch)

  useEffect(() => {
    dispatch(getProfileDetailsById(profileIdParam))

  }, [])

  const backViewDetailsUrl ='/PublicProfile?id='+ profileIdParam

  const onProfileBackbuttonClick = () => {
   navigate(backViewDetailsUrl)
}
  // const generateYears = (startYear, endYear) => {
  //   const years = [];
  //   for (let year = startYear; year <= endYear; year++) {
  //     years.push(year);
  //   }
  //   return years;
  // };

  const rasis = [
    'மேஷம்', 'ரிஷபம்', 'மிதுனம்', 'கடகம்', 'சிம்மம்', 'கன்னி',
    'துலாம்', 'விருச்சிகம்', 'தனுசு', 'மகரம்', 'கும்பம்', 'மீனம்'
  ];
  const rasiEng = ['mesha', 'vrishba', 'mithuna', 'karkata', 'simha', 'kanya',
    'tula', 'vrishika', 'dhanu', 'makara', 'khumbha', 'meena'
  ]
  const planates = [
    'லக்கினம்', 'சூரியன்', 'சந்திரன்', 'செவ்வாய்', 'புதன்',
    'குரு', 'சுக்கிரன்', 'சனி', 'இராகு', 'கேது'
  ];
  const planatesPdf = [
    'ல', 'சூ', 'சந்', 'செ', 'பு',
    'குரு', 'சுக்', 'சனி', 'ராகு', 'கேது'
  ];


  function createInitialCheckedStateR(profileUploadImage) {

    let initialCheckedStateR = {};

    // Ensure profile and horoScope are available before proceeding
    const horoScope = profileUploadImage?.profileDetails?.horoScope;
    if (!horoScope) {
      return initialCheckedStateR = {}; // Return an empty object if horoScope is undefined or null
    }
  
    // Precompute the index lookup for Rasis
    const rasiIndexLookup = rasis.reduce((acc, rasi, index) => {
      acc[rasi] = index;
      return acc;
    }, {});


    // Create a lookup object to map elements of planates to planatesPdf
    const planatesMapping = planatesPdf.reduce((acc, planet, index) => {
      acc[planet] = planates[index];
      return acc;
    }, {});
  

    // Loop through the Rasis and Planets to create the key-value pairs
    rasis.forEach((rasi) => {
      planatesPdf.forEach((planatesPdf) => {
            // Construct the key for the combination of Rasi and Planet
            const key = `${rasi}-${planatesMapping[planatesPdf]}`;
            
            // Safely check if the Rasi and Planet exist in the horoScope
            const rasiKey = `${rasiEng[rasiIndexLookup[rasi]].toLowerCase()}R`;
            if (horoScope[rasiKey] && horoScope[rasiKey].includes(planatesPdf)) {
              // Set the initial state as true if the planet is found in that Rasi
              initialCheckedStateR[key] = true;
            } else {
              // Otherwise, set the initial state as false
              initialCheckedStateR[key] = false;
            }
      });
    });
  
    return initialCheckedStateR;
  }  


  function createInitialCheckedStateA(profileUploadImage) {

    let createInitialCheckedStateA = {};

    // Ensure profile and horoScope are available before proceeding
    const horoScope = profileUploadImage?.profileDetails?.horoScope;
    if (!horoScope) {
      return createInitialCheckedStateA = {}; // Return an empty object if horoScope is undefined or null
    }
  
    // Precompute the index lookup for Rasis
    const rasiIndexLookup = rasis.reduce((acc, rasi, index) => {
      acc[rasi] = index;
      return acc;
    }, {});


    // Create a lookup object to map elements of planates to planatesPdf
    const planatesMapping = planatesPdf.reduce((acc, planet, index) => {
      acc[planet] = planates[index];
      return acc;
    }, {});
  

    // Loop through the Rasis and Planets to create the key-value pairs
    rasis.forEach((rasi) => {
      planatesPdf.forEach((planatesPdf) => {
            // Construct the key for the combination of Rasi and Planet
            const key = `${rasi}-${planatesMapping[planatesPdf]}`;
            
            // Safely check if the Rasi and Planet exist in the horoScope
            const rasiKey = `${rasiEng[rasiIndexLookup[rasi]].toLowerCase()}A`;
            if (horoScope[rasiKey] && horoScope[rasiKey].includes(planatesPdf)) {
              // Set the initial state as true if the planet is found in that Rasi
              createInitialCheckedStateA[key] = true;
            } else {
              // Otherwise, set the initial state as false
              createInitialCheckedStateA[key] = false;
            }
      });
    });
  
    return createInitialCheckedStateA;
  }  


  // State to store the checked items
  const [checkedItemsRasi, setCheckedItemsRasi] = useState({});
  const [checkedItemsAmsam, setCheckedItemsAmsam] = useState({});

  useEffect(() => {
    // Ensure profileUploadImage is available and not undefined
    if (profileUploadImage) {
      // Generate the initial checked state objects based on profileUploadImage
      const initialCheckedStateR = createInitialCheckedStateR(profileUploadImage);
      const initialCheckedStateA = createInitialCheckedStateA(profileUploadImage);

      // Set the state for checked items Rasi and Amsam
      setCheckedItemsRasi(initialCheckedStateR);
      setCheckedItemsAmsam(initialCheckedStateA);
    }
  }, [profileUploadImage]); // Run the effect whenever profileUploadImage changes

  
  const startYear = 2020;
  const endYear = 2025;

  const [formTextData,setformTextData] = useState({
    dhasa:'',
    year:'',
    month:'',
    day:''
  })

  const {dhasa,year,month,day} = formTextData

  const onchange = (e) => {
    setformTextData((prevState)=>({
          ...prevState,
          [e.target.name]:e.target.value
      }))
  }

  const handleCheckBoxChange = (rasi, planet) => {
    const key = `${rasi}-${planet}`;
    setCheckedItemsRasi((prevCheckedItemsRasi) => ({
      ...prevCheckedItemsRasi,
      [key]: !prevCheckedItemsRasi[key],
    }));
  };

  const handleCheckBoxChangeAmsam = (rasi, planet) => {
    const key = `${rasi}-${planet}`;
    setCheckedItemsAmsam((prevCheckedItemsAmsam) => ({
      ...prevCheckedItemsAmsam,
      [key]: !prevCheckedItemsAmsam[key],
    }));
  };

  var horoscopeData = {

    profileId:profileIdParam,
    meshaR: "",
    vrishbaR: "",
    mithunaR: "",
    karkataR: "",
    simhaR: "",
    kanyaR: "",
    tulaR: "",
    vrishikaR: "",
    dhanuR: "",
    makaraR: "",
    khumbhaR: "",
    meenaR: "",

    meshaA: "",
    vrishbaA: "",
    mithunaA: "",
    karkataA: "",
    simhaA: "",
    kanyaA: "",
    tulaA: "",
    vrishikaA: "",
    dhanuA: "",
    makaraA: "",
    khumbhaA: "",
    meenaA: "",

    dhasa: "",
    year: "",
    month: "",
    day: ""
  }

  function constructHoroscope() {
    Object.keys(checkedItemsRasi).forEach(function (key) {
      
      if (checkedItemsRasi[key]== true) {
        let rasi = key.split("-")[0]
        let planet = key.split("-")[1]
        let giragangalOnRasi = horoscopeData[rasiEng[rasis.indexOf(rasi)]+"R"] +" " + planatesPdf[planates.indexOf(planet)];
        if(giragangalOnRasi.indexOf("ல") !=-1)
          {
            giragangalOnRasi = "ல" + " " + giragangalOnRasi.replace("ல","")
          }
          horoscopeData[rasiEng[rasis.indexOf(rasi)]+"R"] = giragangalOnRasi
      }
      
    });

    Object.keys(checkedItemsAmsam).forEach(function (key) {
      
      if (checkedItemsAmsam[key]== true) {
        let rasi = key.split("-")[0]
        let planet = key.split("-")[1]
        let giragangalOnRasi = horoscopeData[rasiEng[rasis.indexOf(rasi)]+"A"] +" " + planatesPdf[planates.indexOf(planet)];
        if(giragangalOnRasi.indexOf("ல") !=-1)
          {
            giragangalOnRasi = "ல" + " " + giragangalOnRasi.replace("ல","")
          }
          horoscopeData[rasiEng[rasis.indexOf(rasi)]+"A"] = giragangalOnRasi
      }
      
    });

    horoscopeData.dhasa = dhasa
    horoscopeData.year = year
    horoscopeData.month = month
    horoscopeData.day = day

dispatch(updateHoroscope(horoscopeData))
setTimeout(function() {
  navigate(backViewDetailsUrl, { replace: true });
}, 5000);
  }


  function uploadImage() {
    if(filedata !=undefined)
    dispatch(uploadfile([filedata, "data"]))
  }

  const [fileurl, setFileUrl] = useState();
  const [filedata, setFiledata] = useState();


  function handleChange(e) {
    console.log(e.target.files);


    const formData = new FormData()
    //var newFile = new File(e.target.files[0], "magesh");

    const uploadedFile = e.target.files[0];
    const newName = `${Date.now()}`;
    const fileExtension = uploadedFile.name.split('.').pop();
    const modifiedFile = new File([uploadedFile], newName +"."+ fileExtension , { type: uploadedFile.type });
    setFileUrl(URL.createObjectURL(modifiedFile));
    formData.append("file", modifiedFile)
    formData.append("profileId", profileIdParam)
    setFiledata(formData)
  }
  
  
  

  return (<>

    <div>
    <Link to={backViewDetailsUrl} className="dropdown-item d-flex align-items-center">
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#1aa179" className="bi bi-arrow-left-circle" viewBox="0 0 16 16">
  <path fillRule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z"/>
</svg><p className="h6 mb-0 ms-2">Go Back</p></Link>

<div>
    <br />
</div>
      {(profileUploadImage && profileUploadImage.profileDetails) ? (<div>
        <div><p className="h4">Upload Image</p>


          <div className="row">
            <label style={{ fontWeight: 'bold' }} className="col-4 col-form-label">Profile ID</label>
            <div className="col-8">

              <label className="form-control-plaintext">: {profileUploadImage.profileDetails.profileID}</label>
            </div>
          </div>

          <div className="row">
            <label style={{ fontWeight: 'bold' }} className="col-4 col-form-label">Name</label>
            <div className="col-8">

              <label className="form-control-plaintext">: {profileUploadImage.profileDetails.name}</label>
            </div>
          </div>
          <div className="row">
            <label style={{ fontWeight: 'bold' }} className="col-4 col-form-label">Qualification</label>
            <div className="col-8">
              <label className="form-control-plaintext">: {profileUploadImage.profileDetails.qualification}</label>
            </div>
          </div>

          <div className="row">
            <label style={{ fontWeight: 'bold' }} className="col-4 col-form-label">Job</label>
            <div className="col-8">
              <label className="form-control-plaintext">: {profileUploadImage.profileDetails.job}</label>
            </div>
          </div>

          <div className="row">
            <label style={{ fontWeight: 'bold' }} className="col-4 col-form-label">Salary per month</label>
            <div className="col-8">
              <label className="form-control-plaintext">: {profileUploadImage.profileDetails.salary}</label>
            </div>
          </div>


          <div className="row">
            <label style={{ fontWeight: 'bold' }} className="col-4 col-form-label">Father occu.</label>
            <div className="col-8">
              <label className="form-control-plaintext">: {profileUploadImage.profileDetails.fatherOccupation}</label>
            </div>
          </div>
          <div className="row">
            <label style={{ fontWeight: 'bold' }} className="col-4 col-form-label">Mother occ.</label>
            <div className="col-8">
              <label className="form-control-plaintext">: {profileUploadImage.profileDetails.motherOccupation}</label>
            </div>
          </div>
          <div className="row">
            <label style={{ fontWeight: 'bold' }} className="col-4 col-form-label">Religion</label>
            <div className="col-8">
              <label className="form-control-plaintext">: {profileUploadImage.profileDetails.religion}</label>
            </div>
          </div>

          <div className="row">
            <label style={{ fontWeight: 'bold' }} className="col-4 col-form-label">Caste</label>
            <div className="col-8">
              <label className="form-control-plaintext">: {profileUploadImage.profileDetails.caste}</label>
            </div>
          </div>

          <div className="row p-2"></div>

          <br/> <br/>

          <p className="h4">Upload profile photo</p>

          <div className="row p-2">
            <div className="col-md-4 ">

              <div className="mb-3">
                <label htmlFor="formFile" className="form-label">Choose your photo</label>
                <input className="form-control" type="file" onChange={handleChange} id="formFile"></input>
                <span style={{color:"red"}}>* image size should be less than 300kb</span>
              </div>
            </div>
          </div>
          <div className="row  p-2">
            <div className="col-md-4"> <img src={fileurl} /></div>
          </div>



          <div className="row p-2">
            <div className="col-md-4">
              <button onClick={() => uploadImage()} className="primarybutton" >Save Image</button>
              {/* <button onClick={() => onCancelClick()} className="btn btn-danger">Cancel</button> */}
            </div>
          </div>
        </div>
      </div>) : null}

      <div className="row p-2"></div>

      <br/><br/>

      <p className="h4">Update Horoscope</p>

      <div className='row'>

<div className='col-md-6'>
  <div className='row'>
  <div className='col-md-3'>
      <label >திசை இருப்பு</label>
    </div>
    <div className='col-md-3'>
      <select className={"form-select form-select-sm"} name="dhasa" id="dhasa" onChange={onchange} aria-label=".form-select-sm example">
        <option value="">Select</option>
        <option value={"சூரியன்"}>சூரியன்</option>
        <option value={"சந்திரன் "}>சந்திரன் </option>
        <option value={"செவ்வாய்"}>செவ்வாய்</option>
        <option value={"புதன் "}>புதன்</option>
        <option value={"குரு"}>குரு</option>
        <option value={"சுக்ரன் "}>சுக்ரன் </option>
        <option value={"சனி"}>சனி</option>
        <option value={"இராகு "}>இராகு</option>
        <option value={"கேது"}>கேது</option>

      </select>
    </div>
    <div className='col-md-2'>
      <label >வருடம்</label>
    </div>
    <div className='col-md-4'>
      <select className={"form-select form-select-sm"} name="year" id="year" onChange={onchange} aria-label=".form-select-sm example">
        <option value="">Select</option>
        {
          [...Array(19)].map((_, i) => i + 1)
            .map(i => <option key={i} value={i}>{i}</option>)
        }
      </select>
    </div>
    
  </div>

</div>

<div className='col-md-6'>
  <div className='row'>
  <div className='col-md-2'>
      <label>மாதம்</label>
    </div>
    <div className='col-md-4'>
      <select className={"form-select form-select-sm"} name="month" onChange={onchange} id="month" aria-label=".form-select-sm example">
        <option value="">Select</option>
        {
          [...Array(12)].map((_, i) => i + 1)
            .map(i => <option key={i} value={i}>{i}</option>)
        }
      </select>
    </div>
    
    <div className='col-md-2'>
      <label >நாள்</label>
    </div>
    <div className='col-md-4'>
      <select className={"form-select form-select-sm"} name="day" id="day" onChange={onchange} aria-label=".form-select-sm example">
        <option value="">Select</option>
        {
          [...Array(60)].map((_, i) => i + 1)
            .map(i => <option key={i} value={i}>{i}</option>)
        }
      </select>
    </div>
   
  </div>

</div>


{/* <select>
{
[...Array(10)].map((_, i) => i + 1)
          .map(i => <option key={i} value={i}>{i}</option>)
}
</select> */}


</div>

{/* Adding static grid */}
<br/><br/>
<div className="col-md-8">
        <div className="row">
          <div className="col-3" style={{ borderWidth: '1.5px', borderStyle: 'solid', borderColor: '#1aa179' }}>12. மீனம்</div>
          <div className="col-3" style={{ borderWidth: '1.5px', borderStyle: 'solid', borderColor: '#1aa179' }}>1. மேஷம்</div>
          <div className="col-3" style={{ borderWidth: '1.5px', borderStyle: 'solid', borderColor: '#1aa179' }}>2. ரிஷபம்</div>
          <div className="col-3" style={{ borderWidth: '1.5px', borderStyle: 'solid', borderColor: '#1aa179' }}>3. மிதுனம்</div>
        </div>
      <div className="row">
          <div className="col-3" style={{ borderWidth: '1.5px', borderStyle: 'solid', borderColor: '#1aa179' }}>11. கும்பம்</div>
          <div className="col-6 text-center"><br/>Sample grid</div>
          <div className="col-3" style={{ borderWidth: '1.5px', borderStyle: 'solid', borderColor: '#1aa179' }}>4. கடகம்</div>
      </div>
      <div className="row">
          <div className="col-3" style={{ borderWidth: '1.5px', borderStyle: 'solid', borderColor: '#1aa179' }}>10. மகரம்</div>
          <div className="col-6"></div>
          <div className="col-3" style={{ borderWidth: '1.5px', borderStyle: 'solid', borderColor: '#1aa179' }}>5. சிம்மம்</div>
      </div>
      <div className="row">
          <div className="col-3" style={{ borderWidth: '1.5px', borderStyle: 'solid', borderColor: '#1aa179' }}>9. தனுசு</div>
          <div className="col-3" style={{ borderWidth: '1.5px', borderStyle: 'solid', borderColor: '#1aa179' }}>8. விருச்சிகம்</div>
          <div className="col-3" style={{ borderWidth: '1.5px', borderStyle: 'solid', borderColor: '#1aa179' }}>7. துலாம்</div>
          <div className="col-3" style={{ borderWidth: '1.5px', borderStyle: 'solid', borderColor: '#1aa179' }}>6. கன்னி</div>
      </div>
</div>

<br/><br/>

      <div className="container">
        {/* <h5>Select Months and Years</h5 > */}
        {/* <div className='row'>
          <div className="form-group col-md-4">
            <label >Select ராசி/அம்சம் <span style={{ color: 'red' }}><b>*</b></span></label>
            <select className={"form-select form-select-sm"} name="kattam" id="kattam" aria-label=".form-select-sm example">
              <option value="">Select</option>
              <option value={"rasi"}>ராசி</option>
              <option value={"amsam"}>அம்சம்</option>
            </select>

          </div>
        </div> */}






<div className='row'>
<p className="h5">Select ராசி</p>
        <div className="table-wrapper">
          <div className="responsive-table">
            <div className="header-row">
              <div className="header-cell"></div> {/* Empty cell for the top-left corner */}
              {planates.map((planet, index) => (
                <div key={index} className="header-cell">{planet}</div>
              ))}
            </div>
            {rasis.map((rasi, rasiIndex) => (
              <div key={rasiIndex} className="data-row">
                <div className="data-cell month-cell">{rasiIndex + 1}. {rasi}</div>
                {planates.map((planet, planetIndex) => (
                  <div key={planetIndex} className="data-cell" data-label={planet}>
                    <input
                      type="checkbox"
                      name={`${rasi}-${planet}`}
                      checked={!!checkedItemsRasi[`${rasi}-${planet}`]}
                      onChange={() => handleCheckBoxChange(rasi, planet)}
                    />
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>

        <div className="row p-2"></div>
        <br /> <br />
        <p className="h5">Select அம்சம்</p>
        <div className="table-wrapper">
          <div className="responsive-table">
            <div className="header-row">
              <div className="header-cell"></div> {/* Empty cell for the top-left corner */}
              {planates.map((planet, index) => (
                <div key={index} className="header-cell">{planet}</div>
              ))}
            </div>
            {rasis.map((rasi, rasiIndex) => (
              <div key={rasiIndex} className="data-row">
                <div className="data-cell month-cell">{rasiIndex+1}. {rasi}</div>
                {planates.map((planet, planetIndex) => (
                  <div key={planetIndex} className="data-cell" data-label={planet}>
                    <input
                      type="checkbox"
                      name={`${rasi}-${planet}A`}
                      checked={!!checkedItemsAmsam[`${rasi}-${planet}`]}
                      onChange={() => handleCheckBoxChangeAmsam(rasi, planet)}
                    />
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>

  </div>

      </div>


      {isUpdateHoroscopeLoading && (
            <div className="overlay">
              <div className="loading-spinner"></div>
            </div>
          )}


        <div className="button-container">
          <button onClick={() => constructHoroscope()} className="primarybutton" >Save and close</button>
          <button  onClick={() =>onProfileBackbuttonClick()} className="secondarybutton">Cancel</button>
        </div>

    </div>

  </>
  )

}
