import{createSlice,createAsyncThunk} from '@reduxjs/toolkit'
import brokService  from '../Services/brokService'
import azureService  from '../Services/azureService'
import axios from 'axios'

const authAxious = axios.create({
    baseURL:'http://localhost:5000/api',
    headers:{
        Authorization:`Bearer ${sessionStorage.getItem("Authorization-key")}`
    },
})

const initialState ={
    brokers:[],
    broker:{},
    brokerImageUrl:'',
    brokeremail:'',
    brokerTotal:  0,
    brokerImageList:[],
    brokerDetails:{},
    isRegisterBrokerError:false,
    isRegisterBrokerSuccess:false,
    isRegisterBrokerLoading:false,
    message:'',
    RegisterBrokermessage:'',

    isBrokerDetailByIdError:false,
    isBrokerDetailByIdSuccess:false,
    isBrokerDetailByIdLoading:false,
    BrokerDetailByIdmessage:'',

    isUploadProfileError:false,
    isUploadProfileSuccess:false,
    isUploadProfileLoading:false,
    uploadProfilemessage:'',
}
//const token = sessionStorage.getItem("Authorization-key");


// export const fetchBrokers = createAsyncThunk('brok/fetchBrokers',()=>{
    
//     return axios.get('http://localhost:5000/api/broker/getAllBroker1').then((response)=> response.data
   
//     )
// })

// export const fetchBrokers12 = createAsyncThunk('brok/fetchBrokers',()=>{
   
    
//    return authAxious.get('/broker/getAllBroker1')
// .then((res) => res.data)
// .catch((error) => {
//   console.error(error)
// })


   
// })


export const fetchBrokers = createAsyncThunk(
    'brok/getAllBrokers',
    async(data,thunkAPI)=>{
        try{
             return await brokService.fetchAllBrokers(data)
        }
        catch(error){
            const message = (error.response && error.response.data &&
                error.response.data.message) || error.message || error.tostring()

                return thunkAPI.rejectWithValue(message)
        }
    }
)

export const registerBroker = createAsyncThunk(
    'brok/registerBroker',
    async(data,thunkAPI)=>{
        try{
             return await brokService.registerBroker(data)
        }
        catch(error){
            const message = (error.response && error.response.data &&
                error.response.data.message) || error.message || error.tostring()

                return thunkAPI.rejectWithValue(message)
        }
    }
)




export const getAllBrokers = createAsyncThunk(
    'brok/getAllBrokers',
    async(data,thunkAPI)=>{
        try{
             //return await brokService.getAllBrokers()
             return await brokService.fetchAllBrokers()
        }
        catch(error){
            const message = (error.response && error.response.data &&
                error.response.data.message) || error.message || error.tostring()

                return thunkAPI.rejectWithValue(message)
        }
    }
)

export const getBrokerDetailById = createAsyncThunk(
    'brok/getBrokerDetailById',
    async(data,thunkAPI)=>{
        try{
             return await brokService.getBrokerDetailById(data)
        }
        catch(error){
            const message = (error.response && error.response.data &&
                error.response.data.message) || error.message || error.tostring()

                return thunkAPI.rejectWithValue(message)
        }
    }
)

export const uploadfile = createAsyncThunk(
    'brok/uploadBrokerImage',
    async(fileData,thunkAPI)=>{
        try{
             return await azureService.uploadBrokerImage(fileData);
        }
        catch(error){
            const message = (error.response && error.response.data &&
                error.response.data.message) || error.message || error.tostring()

                return thunkAPI.rejectWithValue(message)
        }
    }
)



const initializeBrokerList = (state) =>{
    state.brokers = []
    state.broker ={}
}

export const brokSlice = createSlice({
    name:'brok',
    initialState,
    reducers:{
        resetRegisterBroker:(state)=>{
            state.isRegisterBrokerLoading = false
            state.isRegisterBrokerError = false
            state.isRegisterBrokerSuccess = false
            state.RegisterBrokermessage = ''
           // initializeBrokerList(state);
        },
        resetImageUploadByState:(state)=>{
            state.uploadProfilemessage = ''
            state.isUploadProfileSuccess = false
            state.isUploadProfileLoading = false
            state.isUploadProfileError = false
        },
    },
    extraReducers:(builder)=>{
        builder
        .addCase(fetchBrokers.pending,(state)=>{
            state.isfetchBrokerLoading = true;
        })
        .addCase(fetchBrokers.fulfilled,(state,action)=>{
            state.isfetchBrokerLoading = false
            state.isfetchBrokerSuccess = true
           state.brokers = action.payload.brokerList
           state.brokerTotal =  action.payload.totalRecourd
           state.brokerImageList =action.payload.imageUrls
        })
        .addCase(fetchBrokers.rejected,(state,action)=>{
            state.isfetchBrokerLoading = false
            state.isfetchBrokerError = true
            state.fetchBrokermessage = action.payload
            state.brokers = []
            state.brokerTotal =  0
            state.brokerImageList =[]
        })
        .addCase(registerBroker.pending,(state)=>{
            state.isRegisterBrokerLoading = true;
        })
        .addCase(registerBroker.fulfilled,(state,action)=>{
            state.isRegisterBrokerLoading = false
            state.isRegisterBrokerSuccess = true
            state.broker = action.payload
        })
        .addCase(registerBroker.rejected,(state,action)=>{
            state.isRegisterBrokerLoading = false
            state.isRegisterBrokerError = true
            state.RegisterBrokermessage = action.payload
            state.broker = {}
        }) 
        .addCase(getBrokerDetailById.pending,(state)=>{
            state.isBrokerDetailByIdLoading = true;
        })
        .addCase(getBrokerDetailById.fulfilled,(state,action)=>{
            state.isBrokerDetailByIdLoading = false
            state.isBrokerDetailByIdSuccess = true
            state.brokerDetails = action.payload.brokerDetails
            state.brokerImageUrl = action.payload.imageUrl
            debugger;
            state.brokeremail = action.payload.email
        })
        .addCase(getBrokerDetailById.rejected,(state,action)=>{
            state.isBrokerDetailByIdLoading = false
            state.isBrokerDetailByIdError = true
            state.BrokerDetailByIdmessage = action.payload
            state.brokerDetails = {}
            state.brokerImageUrl = ''
            state.brokeremail = ''
        })
        .addCase(uploadfile.pending,(state)=>{
            state.isUploadProfileLoading = true;
        })
        .addCase(uploadfile.fulfilled,(state,action)=>{
            state.isUploadProfileLoading = false
            state.isUploadProfileSuccess = true
            debugger
            state.UploadProfilemessage = action.payload.data
        })
        .addCase(uploadfile.rejected,(state,action)=>{
            state.isUploadProfileLoading = false
            state.isUploadProfileError = true
            state.UploadProfilemessage = ''
        })
    }    
})


export const {resetRegisterBroker} = brokSlice.actions
export default brokSlice.reducer