import { useState,useEffect } from "react"
import { useSelector,useDispatch} from "react-redux"
import { Link,useNavigate } from "react-router-dom"
import { getAllDistricts,createdistricts,resetcreateDistrict } from "../../Features/Slices/masterSlice"
import { Modal } from "react-bootstrap"
import Button from 'react-bootstrap/Button';
import { toast } from "react-toastify"


export function DistrictList (){
  const dispatch=useDispatch()
  const navigate=useNavigate()

  const [formData, setFormData] = useState({
    district: ''
  })
  const { district} = formData;
  const onchange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }))
  }

 const {isDistrictListLoading,isDistrictListSuccess,iscreateDistrictLoading,iscreateDistrictSuccess,createDistrictMessage,districts} = useSelector((state) => state.master)

 useEffect(() => {
    
        if(isDistrictListLoading == false && isDistrictListSuccess == false){
               dispatch(getAllDistricts())

              }
              if(iscreateDistrictSuccess){
                toast.success(createDistrictMessage)
                dispatch(resetcreateDistrict())
              }
           setTimeout(() => {dispatch(getAllDistricts())}, 1000);
        },[isDistrictListLoading,isDistrictListSuccess,iscreateDistrictLoading,iscreateDistrictSuccess,createDistrictMessage],dispatch)

        const backViewDetailsUrl = '/ProfileList'
     const onbackClick = (e) => {
       e.preventDefault();
       navigate(backViewDetailsUrl)
     }


        
       const onsubmit = (e) => {
        e.preventDefault(); 
        
        const districtsubmit = {district}
        console.log(districtsubmit)
        dispatch(createdistricts(districtsubmit))
       
    
      }
        const [show, setShow] = useState(false);
        const [ModelPopupData, setModelPopupData] = useState({
            title: 'Add District',
            subTitle: 'District',
            text: 'Please enter district',
        })

        const { title, subTitle, text } = ModelPopupData

        const handleClose = () => setShow(false);

        const handleShow = (popuptype) => {
            setShow(true)
        
            if (popuptype == "AddDistrict") {
              setModelPopupData({
                title: "Add District",
                subTitle: "District",
                text:"submit"
              })
        
            }
            else {
              setModelPopupData({
                title: "Delete District",
                subTitle: "District"
              })
        
            }
       }


       const [isDisplayMode, setIsVisible] = useState(true);
  const toggleVisibility = () => {
    setIsVisible(!isDisplayMode);
  }

  const updateDistrict = (districts) => {
    setIsVisible(!isDisplayMode);
  }
  
    return(<>
    
     <Link onClick={onbackClick}className="text-success text-decoration-none"><h3>Home<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-house-door" viewBox="0 0 15 18">
          <path d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293zM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4z" />
        </svg></h3></Link>

        <nav className="navbar navbar-light">
      <div className="container-fluid">
        <a className="navbar-brand"><h3>Districts</h3></a>
        <div className="d-flex">
          <input className="form-control border-success" type="search" placeholder="Search" aria-label="Search" />

          <button className="btn btn-success me-2 rounded-0"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
          </svg></button>

          <button className="btn btn-outline-success col-4" type="submit" onClick={() => handleShow("AddDistrict")}>Add more</button>
        </div>
      </div>
    </nav>

    <div className="container-fluid py-0">
      <div className="b_wpt_bl colSpan2 rowSpan2 b_wpt_bl_h b_wpt_bl_back" >
        <div className="row row-cols-1 row-cols-lg-3 g-2 g-lg-3">
          {districts.map((districts, index) => (
            <div key={index} className="col">
              <div className="p-3 border border-dark rounded ">
                District

                <div className="float-end">
                {isDisplayMode &&
                    <Link  style={{ marginLeft: '250px' }}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="" className="bi bi-pencil-square" viewBox="0 0 16 16">
                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                        <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z" />
                      </svg>
                    </Link>}
                    {isDisplayMode &&
                    <Link onClick={() => handleShow("DeleteDistrict")} style={{ marginLeft: '40px' }}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="" className="bi bi-trash" viewBox="0 0 16 16">
                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                        <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                      </svg>
                    </Link>}</div>
              

               <div className="flot-start">
                  {isDisplayMode && <b>{districts.district} </b>}</div>

                {!isDisplayMode &&
                  <input type="text" value={districts.district}
                    name="districts" id="districts"
                    className="border border-success p-1"></input>
                }
                <div className="float-end">
                  {!isDisplayMode &&
                    <button type="button" class="btn-close" onClick={toggleVisibility} aria-label="Close"></button>
                  }
                  {!isDisplayMode &&
                    <svg xmlns="http://www.w3.org/2000/svg" onClick={() => updateDistrict(districts)} style={{ marginLeft: '15px' }} width="16" height="16" fill="currentColor" class="bi bi-check2-circle" viewBox="0 0 16 16">
                      <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0" />
                      <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0z" />
                    </svg>
                  }</div>

              </div>
            </div>
          ))}
        </div>
      </div>
    </div>

    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <form onSubmit={onsubmit}>
        <Modal.Body>
          <div className="form-group">
            <h2>{subTitle}</h2>
            <input className="form-control" type="text" name="district" id="district" placeholder="Enter the district" onChange={onchange} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="dark" onClick={handleClose}>
            Close
          </Button>
          <Button type="submit" variant="success" onClick={handleClose}>
            Submit
          </Button>
        </Modal.Footer>
      </form>
    </Modal>    
    </>)
}